import React from "react";
import { Helmet } from "react-helmet";
import { Header } from "./Header";
import "./Layout.css";
import { defineCustomElements } from "@deckdeckgo/highlight-code/dist/loader";
defineCustomElements();

export const Layout = ({ children, pageTitle }) => {
  return (
    <div className="w-full h-screen bg-light text-copy-base overflow-y-scroll">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="container mx-auto pb-6 md:pb-20">
        <Header />
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
};
