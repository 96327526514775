import { Link } from "gatsby";
import React from "react";

export const Header = () => {
  return (
    <div className="p-4">
      <h1 className="text-2xl">
        Hi,{" "}
        <Link to="/" className="underline" activeClassName="font-bold">
          I'm Beer
        </Link>
      </h1>
      <p className="text-base text-copy-light">
        Here you can find my{" "}
        <Link
          to="/work"
          className="underline"
          activeClassName="font-bold"
          partiallyActive={true}
        >
          work
        </Link>{" "}
        &{" "}
        <Link
          to="/thoughts"
          className="underline"
          activeClassName="font-bold"
          partiallyActive={true}
        >
          thoughts
        </Link>
      </p>
    </div>
  );
};
